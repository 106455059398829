import { AppointmentResponse } from "@9amhealth/openapi";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { useInterval } from "@react-hooks-library/core";
import type { FC } from "react";
import React, { useEffect } from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import { DateFormats, dateLocal } from "src/lib/date";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import { tracker } from "src/state/state";
import { TrackEvent } from "src/state/Track/TrackCubit";
import {
  AppointmentAction,
  MoreAppointmentAction
} from "src/ui/components/AppointmentsList/components/AppointmentAction";
import { DateIndicator } from "src/ui/components/AppointmentsList/components/DateIndicator";
import {
  AppPopup,
  useAppQueryPopup
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import Translate from "src/ui/components/Translate/Translate";

export const AppointmentCardLayout = styled.div`
  --padding: 1rem;
  --active-background: var(--color-cream);

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    --padding: 1.5rem;
    --active-background: white;

    &:hover {
      box-shadow: var(--large-shadow);
    }
  }

  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.1s ease-in-out;
  color: inherit;
  padding: var(--padding);
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);

  &:active {
    background: var(--active-background);
  }

  &[data-loading="true"] {
    opacity: 0.6;
    pointer-events: none;
  }

  .display {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }

  .label {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.875rem;
    color: var(--color-charcoal-80);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 1rem;
  }

  .time {
    font-weight: 500;
    color: var(--color-charcoal-80);
  }

  .cta {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    gap: 1rem;
  }

  .primary-actions-wrapper {
    display: flex;
    gap: 0.5rem;

    .appointment-action {
      &:nth-of-type(2) ~ .appointment-action {
        display: none;
      }
    }
  }
`;

const AppointmentCard: FC<{
  appointment: AppointmentResponse;
}> = ({ appointment }) => {
  const [, { loadAppointments, minutesToStart, createAppointmentConfig }] =
    useBloc(AppointmentsBloc);
  const { start, end, id, title, description } = appointment;
  const [showMinToStart, setShowMinToStart] = React.useState<number | null>(
    null
  );
  const config = createAppointmentConfig(appointment);

  const updateShowMinToStart = () => {
    const diffMinutes = minutesToStart(appointment);
    if (diffMinutes >= 60) {
      setShowMinToStart(null);
      return;
    }
    setShowMinToStart(diffMinutes);
  };

  useInterval(() => {
    updateShowMinToStart();
  }, 1000);

  useEffect(() => {
    updateShowMinToStart();
  }, []);

  const onPopupClose = () => {
    // reload appointments when the popup is closed
    void loadAppointments();
  };

  const [openPopup] = useAppQueryPopup(AppPopup.appointment, {
    onClose: onPopupClose
  });

  const handleClick = () => {
    tracker.track(TrackEvent.AppointmentItemClicked, {
      data: {
        "Appointment ID": id,
        "Appointment Title": title
      }
    });

    openPopup({
      additionalParameters: {
        id
      }
    });
  };

  return (
    <AppointmentCardLayout>
      <div className="display" onClick={handleClick}>
        <div>
          <div className="time">
            {showMinToStart === null ? (
              <Translate
                msg="appointment.eventDateTime"
                variables={{
                  startTime: dateLocal(start).format(
                    DateFormats.DISPLAY_TIME_ALT
                  ),
                  endTime: dateLocal(end).format(DateFormats.DISPLAY_TIME_ALT),
                  timeZone: dateLocal().format("z")
                }}
              />
            ) : (
              <Translate
                msg="appointment.relativeMinutes"
                variables={{
                  minutes: Math.abs(showMinToStart),
                  context: showMinToStart > 0 ? "before" : "after"
                }}
              />
            )}
          </div>
          <div className="label">{title}</div>
        </div>

        <DateIndicator date={parseAbsoluteToLocal(start)} />
      </div>
      <div className="description">{description}</div>

      <div className="cta">
        <div className="primary-actions-wrapper">
          {config.cardActions.map((action, index) => (
            <AppointmentAction key={index} config={action} />
          ))}
        </div>

        <MoreAppointmentAction onClick={handleClick} />
      </div>
    </AppointmentCardLayout>
  );
};
export default AppointmentCard;
