import { keyframes } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { IconPlayArrow } from "src/constants/icons";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import {
  AppPopup,
  useAppQueryPopup
} from "../AppQueryPopups/AppQueryPopupsBloc";
import useVideoPopup from "../FullVideoPlayer/useVideoPopup";
import type { EducationalContent } from "./UserEducationalFeedBloc";
import { getSupportedUserLanguage } from "src/lib/i18next";
import { languageAsShort, LanguageLong } from "src/constants/language";
import { StorageController } from "src/state/StorageBloc/StorageBloc";

const fadeInKeyframes = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const Card = styled.button`
  width: 100%;
  background: none;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  box-shadow: var(--light-shadow);
  border-radius: 0.5rem;
  animation: ${fadeInKeyframes} 0.3s ease-in-out;
`;

const CardContent = styled.div`
  display: grid;
  align-items: center;
  --img-width: clamp(4rem, 20vw, 8rem);
  grid-template-columns: var(--img-width) auto;
  gap: 0.75rem;
  padding: 1rem;
  position: relative;

  @media screen and (min-width: 450px) {
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1rem;
  }
`;

const ImgWrap = styled.div`
  label: img-wrap;
  position: relative;
  --width: clamp(4rem, 20vw, 8rem);
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);
  flex: 1;
  aspect-ratio: 1;
  z-index: 1;
  border-radius: 0.5em;
  overflow: hidden;

  img {
    z-index: 1;
  }

  svg {
    display: none;
  }

  &[data-type="video"] {
    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.2);
    }
    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      --size: 2rem;
      color: var(--color-white);
      width: var(--size);
      height: var(--size);
    }
  }
`;

const CardTitle = styled.div`
  color: var(--color-charcoal);
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: 140%;
  font-weight: 500;
`;

const CardDescription = styled.div`
  font-family: var(--font-family);
  font-size: calc(var(--font-size) * 0.9);
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.02rem;

  display: none;
  @media screen and (min-width: 450px) {
    display: block;
  }
`;

const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const ArticleCard: FC<{
  article: EducationalContent;
}> = (props) => {
  const { title, image, description, url, type, className, journey } =
    props.article;
  const popupSourceRef = React.useRef<HTMLDivElement>(null);

  const [openDetails] = useAppQueryPopup(AppPopup.article, {
    onClose: () => {
      tracker.track("Article Closed" as TrackEvent, {
        data: {
          url,
          title,
          type,
          className,
          journey
        }
      });
    }
  });

  const [openVideo] = useVideoPopup({
    animationSourceRef: popupSourceRef,
    onClose: () => {
      tracker.track("Article Closed" as TrackEvent, {
        data: {
          url,
          title,
          type,
          className,
          journey
        }
      });
    }
  });

  const openPopover = () => {
    if (type === "article") {
      if (!url) return "";
      openDetails({
        additionalParameters: {
          url,
          title: title ?? ""
        }
      });
    }
    if (type === "video") {
      const src = props.article.sanctuaryHealthVideo?.landscapeVideo?.path;

      openVideo({
        autoplay: true,
        poster: props.article.sanctuaryHealthVideo?.photoUrl ?? "",
        sources: [
          {
            src: src ?? "",
            type: "video/mp4"
          }
        ],
        tracks:
          props.article.sanctuaryHealthVideo?.landscapeVideo?.captions?.map(
            (caption) => ({
              kind: "captions",
              src: caption.srt?.url ?? "",
              srclang: languageAsShort(caption.language) ?? "",
              label: caption.language as LanguageLong,
              format: 'srt'
            })
          )
      });
    }

    tracker.track("Article Opened" as TrackEvent, {
      data: {
        url,
        title,
        type,
        className,
        journey
      }
    });
  };

  return (
    <>
      <Card onClick={openPopover}>
        <CardContent>
          <ImgWrap data-type={type} ref={popupSourceRef}>
            <ResponsiveImage
              image={image}
              initialAspectRatio={1}
              fillContainer
            />
            <IconPlayArrow />
          </ImgWrap>

          <CardContentText>
            <CardTitle>{title}</CardTitle>
            {description && <CardDescription>{description}</CardDescription>}
          </CardContentText>
        </CardContent>
      </Card>
    </>
  );
};

export default ArticleCard;
