import { TaskResponse } from "@9amhealth/openapi";
import type { SanityImageAssetDocument } from "@sanity/client";
import { Cubit } from "blac";
import { Blac } from "blac-next";
import dayjs from "dayjs";
import SanityService from "src/api/SanityService";
import Language from "src/constants/language";
import { dateLocal } from "src/lib/date";
import type { Category } from "src/lib/getEducationalContent";
import { DefaultCategory, JourneyName } from "src/lib/getEducationalContent";
import { getSupportedUserLanguage } from "src/lib/i18next";
import sanityQuery from "src/lib/sanityQuery";
import { AppRemoteConfigCubit } from "src/state/AppRemoteConfigCubit/AppRemoteConfigCubit";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { TaskResponseKnown } from "src/state/TaskManagementBloc/TaskManagementBloc";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { tracker, userPreferences, userState } from "src/state/state";

enum EducationalContentType {
  ARTICLE = "article",
  VIDEO = "video"
}

export type SanctuaryHealthVideoMedia = {
  path?: string;
  captions?: Caption[];
};

export type SanctuaryHealthPost = {
  description?: string;
  title?: string;
};

interface Srt {
  url?: string;
  path?: string;
  mimetype?: string;
}

export interface FeedCategory {
  slug: string;
  title: Category;
  id: string;
}

export interface FeedData {
  feed: EducationalContent[];
  categories: FeedCategory[];
  feedLength: number;
  language: Language;
}

export interface Feed {
  data: FeedData;
  params: {
    conditions: string;
    enrolled: string;
    journey: string;
    language: string;
    now: string;
    program: string;
    tasks: string;
  };
}

export interface Caption {
  language?: string;
  srt?: Srt;
}

export type SanctuaryHealthVideo = {
  landscapeVideo?: SanctuaryHealthVideoMedia;
  photoUrl?: string;
  post?: SanctuaryHealthPost;
  title?: string;
};

export interface ContentCategory {
  slug: string;
  title: Category;
  id: string;
}

export interface EducationalContent {
  _id: string;
  title?: string;
  description?: string;
  url?: string;
  image?: SanityImageAssetDocument;
  addedAtUtc?: string;
  type?: EducationalContentType;
  language?: string;
  categories: ContentCategory[];
  sanctuaryHealthVideo?: SanctuaryHealthVideo;
  className?: string;
  journey?: JourneyName;
}

interface EducationalContentInUserPreferences {
  sanityDocumentId: string;
  addedAtUtc: string;
  type: EducationalContentType;
}

interface UserEducationalFeedBlocState {
  articles: EducationalContent[];
  categories: Category[];
  hasMoreContent?: boolean;
}

export default class UserEducationalFeedBloc extends Cubit<UserEducationalFeedBlocState> {
  userClass?: number;
  constructor() {
    super({
      articles: [],
      categories: []
    });
  }

  get userAssignedArticles(): Record<string, unknown> {
    const userPreferencesState = userPreferences.state as unknown as Record<
      string,
      unknown
    >;

    const keys = Object.keys(userPreferencesState);
    // Get all data object keys that start with 'user.careteamassigned.educationalcontent.'
    const educationalContentKeys = keys.filter((key) =>
      key.startsWith(UserPreferenceKeys.userEducationalContent + ".")
    );
    // Create new object from data object that contains only filtered keys
    const educationalContentPreferences = educationalContentKeys.reduce(
      (preferenceObject: typeof userPreferencesState, key) => {
        preferenceObject[key] = userPreferencesState[key];
        return preferenceObject;
      },
      {}
    );
    return educationalContentPreferences;
  }

  get userJourneys() {
    return userPreferences.state[UserPreferenceKeys.journeyEnrollment] ?? [];
  }

  loadFromUserPreferences: () => Promise<EducationalContent[]> = async () => {
    // Get educational content from user preferences
    let dataFromUserPreferences: EducationalContent[] = [];
    // Create array of educational content values
    const educationalContentArrayFromUserPreferences = Object.values(
      this.userAssignedArticles
    ) as EducationalContentInUserPreferences[];

    if (educationalContentArrayFromUserPreferences.length > 0) {
      dataFromUserPreferences = await SanityService.fetchSanityData(
        sanityQuery.getByIds(
          educationalContentArrayFromUserPreferences
            .map((ec) => JSON.stringify(ec.sanityDocumentId))
            .join(",")
        )
      );
    }

    return dataFromUserPreferences;
  };

  loadPromisesFromCms: (filter: {
    program?: KnownProgram;
  }) => Promise<EducationalContent[]> = async (filter) => {
    // Get educational content from Sanity CMS
    const language = getSupportedUserLanguage();
    const cmsItems = filter.program
      ? await SanityService.fetchSanityData(
          sanityQuery.educationalContentByProgram(filter.program, language)
        )
      : [];

    return cmsItems as EducationalContent[];
  };

  loadEducationalContentOld = async (
    filter: { program?: KnownProgram } = {}
  ) => {
    const parsedContentArray: EducationalContent[] = [];

    // Combine data from user preferences and from Sanity CMS
    const [dataFromUserPreferences = [], programItems = [], genericItems = []] =
      await Promise.all([
        this.loadFromUserPreferences(),
        this.loadPromisesFromCms(filter),
        this.loadPromisesFromCms({ program: "no-program" as KnownProgram })
      ]);
    const data = [...dataFromUserPreferences, ...programItems, ...genericItems];

    // Filter out duplicates (leave the one that occurred first - it is the one from user preferences because of the way the data array is sorted)
    const filteredData = data.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item._id === value._id)
    );
    for (const item of filteredData) {
      const educationalContentObject = this.userAssignedArticles[
        `${UserPreferenceKeys.userEducationalContent}.${item._id}`
      ] as EducationalContentInUserPreferences | undefined;

      item.addedAtUtc = educationalContentObject?.addedAtUtc;
      parsedContentArray.push(item);
    }

    // Sort educational content by added at (newest first)
    let sortedParsedContentArray = [...parsedContentArray].sort((a, b) => {
      if (a.addedAtUtc && b.addedAtUtc) {
        return dayjs(a.addedAtUtc).isAfter(b.addedAtUtc) ? -1 : 1;
      }

      return 1;
    });

    sortedParsedContentArray = sortedParsedContentArray.map((article) => {
      if (article.categories === null) {
        article.categories = [];
      }
      return article;
    });

    this.emit({
      ...this.state,
      articles: sortedParsedContentArray
    });
  };

  loadEducationalContent = async (tasks: TaskResponseKnown[]) => {
    let date = userPreferences.journeys[0]?.date;

    if (!date) {
      date = dateLocal().toISOString();
      const journey = JourneyName.default;
      void userPreferences.updateUserPreferences({
        [UserPreferenceKeys.journeyEnrollment]: [
          {
            journey,
            date
          }
        ]
      });
      tracker.track(TrackEvent.UserEnrolledInJourney, {
        data: {
          journey,
          date
        }
      });
    }

    const programNames = userState.programMemberships
      ?.map((p) => (p.active ? p.program : false))
      .filter(Boolean);

    const conditionsNames = userState.medicalConditions?.map(
      (medicalCondition) => medicalCondition.name
    );

    const completedTasks = tasks
      .filter((task) => task.status === TaskResponse.status.COMPLETED)
      .map((completedTask) => completedTask.slug);
    const availableTasks = tasks
      .filter((task) => task.status === TaskResponse.status.AVAILABLE)
      .map((completedTask) => completedTask.slug);

    const educationalContentUserPreferences = (
      Object.values(
        this.userAssignedArticles
      ) as EducationalContentInUserPreferences[]
    ).map((item) => ({
      id: item.sanityDocumentId,
      date: dateLocal().toISOString()
    }));

    const userLanguage = getSupportedUserLanguage();

    const data = await Blac.getBloc(
      AppRemoteConfigCubit
    ).loadEducationalContent({
      language: userLanguage,
      enrolled: date,
      tasks: {
        completed: completedTasks,
        available: availableTasks
      },
      programs: programNames,
      conditions: conditionsNames,
      curated: educationalContentUserPreferences
    });

    const categoriesNames = data.categories.map(
      (category) => category.slug as Category
    );
    const allAvailableCategories = [DefaultCategory.all, ...categoriesNames];

    this.emit({
      ...this.state,
      articles: data.feed,
      categories: allAvailableCategories
    });
  };
}
