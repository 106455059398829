import { AvailableLabTestsResponse } from "@9amhealth/openapi";
import type { FC } from "react";
import React, { useMemo } from "react";
import { tracker } from "src/state/state";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { getSupportedUserLanguage } from "src/lib/i18next";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import {
  DateFormats,
  dateLocal,
  getCalendarDateFromString
} from "src/lib/date";
import { AppointmentCardLayout } from "./AppointmentCard";
import { TaskResponseKnown } from "src/state/TaskManagementBloc/TaskManagementBloc";
import { ActionConfig } from "src/state/AppointmentsBloc/AppointmentsBloc";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import { AppointmentAction } from "./AppointmentAction";
import { IconFile, IconLocation } from "src/constants/icons";
import Translate from "src/ui/components/Translate/Translate";
import {
  ProviderLocationUrl,
  SupportedLabProvider
} from "src/ui/components/AppQueryPopups/dialogs/LabInstructionsDialog";

export enum LabNames {
  "LABCORP" = "Labcorp",
  "QUEST" = "Quest",
  "KWIK_TRIP_CENTER_FOR_HEALTH" = "KwikTrip"
}

const AppointmentTaskCard: FC<{
  appointmentTask: TaskResponseKnown;
}> = ({ appointmentTask }) => {
  const {
    taskSlug,
    requisitionFileId,
    labProvider,
    location,
    time
  }: {
    taskSlug: string;
    requisitionFileId?: string;
    labProvider?: AvailableLabTestsResponse.labOrderProvider;
    location?: string;
    time?: string;
  } = useMemo(() => {
    return {
      taskSlug: appointmentTask.slug,
      requisitionFileId: appointmentTask.additionalData?.requisitionFileId as
        | string
        | undefined,
      labProvider: appointmentTask.additionalData?.labOrderProvider as
        | SupportedLabProvider
        | undefined,
      location: appointmentTask.additionalData?.location as string | undefined,
      time: appointmentTask.availableFrom ?? ""
    };
  }, [appointmentTask]);

  if (!requisitionFileId || !labProvider) return null;

  const userLanguage = getSupportedUserLanguage();
  const inTwoWeeksDate = getCalendarDateFromString(time).add({
    days: 14
  });

  const weekday = time
    ? dateLocal(inTwoWeeksDate.toString(), {
        locale: userLanguage
      }).format(DateFormats.DISPLAY_WEEKDAY_MONTH_DAY)
    : "";

  const handleClick = () => {
    tracker.track(TrackEvent.AppointmentItemClicked, {
      data: {
        "Appointment Task": taskSlug,
        Provider: labProvider
      }
    });

    AppQueryPopupsController.openPopup(AppPopup.labInstructions, {
      additionalParameters: {
        fileId: requisitionFileId,
        provider: labProvider,
        expectedTime: weekday
      }
    });
  };

  const requisitionFileDownloadAction = {
    label: "requisition",
    icon: <IconFile size={16} />,
    onClick: () => {
      if (!appointmentTask.additionalData?.requisitionFileId) {
        return;
      }
      tracker.track(TrackEvent.AppointmentShowRequisitionClick, {
        type: TrackType.click,
        data: {
          "Appointment Task": appointmentTask.slug
        }
      });
      void FilesCubit.startFileDownload(
        appointmentTask.additionalData.requisitionFileId as string
      );
    }
  };

  const directionAction = {
    label: "directions",
    icon: <IconLocation size={16} />,
    onClick: () => {
      window.open(
        ProviderLocationUrl[labProvider as SupportedLabProvider],
        "_blank"
      );
    }
  };

  const descriptionContext =
    labProvider ===
    AvailableLabTestsResponse.labOrderProvider.KWIK_TRIP_CENTER_FOR_HEALTH
      ? AvailableLabTestsResponse.labOrderProvider.KWIK_TRIP_CENTER_FOR_HEALTH
      : "";

  return (
    <AppointmentCardLayout>
      <div className="display" onClick={handleClick}>
        <div>
          {time && (
            <div className="time">
              <Translate msg="lab.center.label" variables={{ date: weekday }} />
            </div>
          )}
          <div className="label">
            <Translate msg="lab.center.title" />
          </div>
        </div>
      </div>
      <div className="description">
        <Translate
          msg="lab.center.description"
          variables={{
            lab: LabNames[labProvider as keyof typeof LabNames],
            location: location,
            context: descriptionContext
          }}
        />
      </div>
      <div className="cta">
        <div className="primary-actions-wrapper">
          <AppointmentAction key={1} config={directionAction as ActionConfig} />
          <AppointmentAction
            key={2}
            config={requisitionFileDownloadAction as ActionConfig}
          />
        </div>
      </div>
    </AppointmentCardLayout>
  );
};
export default AppointmentTaskCard;
