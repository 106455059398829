import React, { FC, useEffect, useMemo, useState } from "react";
import CollectionTitle from "../StyledComponents/CollectionTitle";
import AppointmentCard from "src/ui/components/AppointmentsList/components/AppointmentCard";
import styled from "@emotion/styled";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import { useBloc } from "@blac/react";
import { useBloc as useBloc2 } from "src/state/state";
import Translate from "src/ui/components/Translate/Translate";
import {
  IconCarouselChevronLeft,
  IconCarouselChevronRight
} from "src/constants/icons";
import { CarouselCubit } from "./components/Carousel/CarouselCubit";
import Carousel from "./components/Carousel/Carousel";
import CarouselItem from "./components/Carousel/CarouselItem";
import Pagination from "./components/Carousel/Pagination";
import { OpacityGradient } from "./components/Carousel/OpacityGradient";
import {
  CarouselButton,
  CarouselButtonWrapper
} from "./components/Carousel/CarouselButton";
import { APP_BREAKPOINT_SM } from "src/constants/layout";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { TaskResponse } from "@9amhealth/openapi";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import AppointmentTaskCard from "./components/AppointmentTaskCard";

const Wrapper = styled.div`
  margin: 1.5rem 0 4rem;

  @media screen and (max-width: ${APP_BREAKPOINT_SM}px) {
    margin-top: 0.5rem;
  }

  position: relative;
`;

const AppointmentsList: FC = () => {
  const [{ scheduledAppointments }] = useBloc(AppointmentsBloc);
  const [{ locked }, { handleScrollTo, itemPrefix }] = useBloc(CarouselCubit);
  const [{ tasks }, { loadProgramTasks }] = useBloc2(TaskManagementBloc);

  const [loading, setLoading] = useState(false);

  const appointmentTask = useMemo(
    () =>
      tasks.find(
        (task) =>
          task.slug === TaskKey.COMPLETE_RECURRING_LAB_ORDER &&
          task.status === TaskResponse.status.AVAILABLE &&
          task?.additionalData?.requisitionFileId
      ),
    [tasks]
  );

  useEffect(() => {
    let mounted = true;
    if (!appointmentTask) {
      setLoading(true);
      void loadProgramTasks(KnownProgram.CARE).finally(() => {
        if (mounted) {
          setLoading(false);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  const elementsCount =
    scheduledAppointments.length + Number(!!appointmentTask);
  const isPaginationHidden = elementsCount <= 1;

  if (!elementsCount || loading) {
    return null;
  }

  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="appointment.list.title" />
      </CollectionTitle>

      <Carousel isPaginationHidden={isPaginationHidden}>
        <OpacityGradient elementsCount={elementsCount} />
        {appointmentTask && (
          <>
            <CarouselItem id={itemPrefix} zIndex={scheduledAppointments.length}>
              <AppointmentTaskCard appointmentTask={appointmentTask} />
            </CarouselItem>
          </>
        )}
        {scheduledAppointments.map((appointment, index) => (
          <CarouselItem
            id={`${itemPrefix}-${index - 1}`}
            key={appointment.id}
            // previous items should have higher z-index to fix clipping shadow issue
            zIndex={scheduledAppointments.length - index - 1}
          >
            <AppointmentCard appointment={appointment} />
          </CarouselItem>
        ))}
        <OpacityGradient elementsCount={elementsCount} />
      </Carousel>

      {!isPaginationHidden && (
        <>
          <Pagination elementsCount={elementsCount} />

          <CarouselButtonWrapper>
            <CarouselButton
              disabled={locked}
              onClick={() => handleScrollTo("prev")}
            >
              <IconCarouselChevronLeft />
            </CarouselButton>

            <CarouselButton
              disabled={locked}
              onClick={() => handleScrollTo("next")}
            >
              <IconCarouselChevronRight />
            </CarouselButton>
          </CarouselButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default AppointmentsList;
